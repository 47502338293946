import Spline from "@splinetool/react-spline";

function Keyboard() {
  return (
    <>
      <Spline scene="https://prod.spline.design/I7rEeHLUR6LUp1ah/scene.spline" />
    </>
  );
}

export default Keyboard;
